<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="tianqin"></Header>
    <BannerTitle title="tianqin"></BannerTitle>
    <div class="tqBox">
      <div class="introduce">
        <div class="con">
          <img src="@/assets/images/tianqin1.png" alt="" />
          <div>
            <span>{{ $t("tq.sumSec.tit1") }}</span>
            <p>{{ $t("tq.sumSec.tips1") }}</p>
          </div>
        </div>
        <div class="con">
          <img src="@/assets/images/tianqin2.png" alt="" />
          <div>
            <span>{{ $t("tq.sumSec.tit2") }}</span>
            <p>{{ $t("tq.sumSec.tips2") }}</p>
          </div>
        </div>
        <div class="con">
          <img src="@/assets/images/tianqin3.png" alt="" />
          <div>
            <span>{{ $t("tq.sumSec.tit3") }}</span>
            <p>{{ $t("tq.sumSec.tips3") }}</p>
          </div>
        </div>
      </div>
      <div class="pattern">
        <div class="title">{{ $t("tq.bmTit") }}</div>
        <img
          src="@/assets/images/tianqin4.png"
          v-show="$i18n.locale == 'zh'"
          alt=""
        />
        <img src="@/assets/images/tianqin4en.jpg" v-show="$i18n.locale == 'en'" alt="" />
      </div>
      <Start></Start>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
import Start from "../../components/stars.vue";
export default {
  name: "tianqin",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline,
    Start
  }
};
</script>

<style lang="scss">
.tqBox {
  width: 1300px;
  margin: auto;
  .introduce {
    width: 932px;
    margin: 45px auto;
    padding: 1px 0;
    .con {
      margin-bottom: 71px;
      clear: both;
      overflow: hidden;
      img {
        width: 72px;
        height: 72px;
        float: left;
        margin-right: 60px;
      }
      div {
        float: left;
        width: 800px;
        span {
          display: block;
          font-size: 24px;
          font-weight: 600;
          color: rgba(51, 51, 51, 1);
          line-height: 33px;
          margin-bottom: 14px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 40px;
        }
      }
    }
  }
  .pattern {
    .title {
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 56px;
      font-size: 40px;
      text-align: center;
    }
    img {
      width: 1200px;
      margin: auto;
      display: block;
      margin-top: 53px;
    }
  }
}
</style>
