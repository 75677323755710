<template>
  <div class="stars">
    <div class="info">
      <img src="@/assets/images/xingchenjia.png" alt="" />
      <p>{{ $t("xcp.sIntro") }}</p>
    </div>
    <div class="more">
      <a href="http://www.xingchenjia.com/" target="_blank">
        {{ $t("xcp.more") }} >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stars"
};
</script>

<style lang="scss">
.stars {
  width: 1127px;
  padding: 71px 91px 71px 82px;
  background: rgba(250, 250, 250, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px 0;
  .info {
    display: flex;
    align-items: center;
    img {
      float: left;
      width: 170px;
      height: 95px;
      margin-right: 55px;
    }
    p {
      width: 629px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 26px;
      float: left;
    }
  }
  .more {
    width: 160px;
    height: 61px;
    background: rgba(255, 255, 255, 1);
    a {
      font-size: 20px;
      font-weight: 600;
      display: block;
      line-height: 61px;
      text-align: center;
      color: rgba(5, 100, 205, 1);
    }
  }
}
</style>
